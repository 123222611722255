<template>
    <!-- eslint-disable -->
    <v-container
        fluid
        grid-list-xl
    >
        <v-layout
            justify-center
            wrap
        >
            <v-flex md12>
                <v-layout>
                    <v-flex md4>
                        <v-menu
                            ref="menuFrom"
                            v-model="menuFrom"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template #activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateFrom"
                                    label="From date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker
                                v-model="dateFrom"
                                type="month"
                                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                min="2015-01-01"
                                @change="changeDates"
                            />
                        </v-menu>
                        <v-menu
                            ref="menuTo"
                            v-model="menuTo"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template #activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateTo"
                                    label="To date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker
                                v-model="dateTo"
                                type="month"
                                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                min="2015-01-01"
                                @change="changeDates"
                            />
                        </v-menu>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-card
                v-if="result.totalCount"
                flat
                class="mr-5"
            >
                <v-card-text>
                    <div>Total Number of Messages:</div>
                    <p class="display-1 text--primary text-center">
                        {{ result.totalCount }}
                    </p>
                </v-card-text>
            </v-card>
            <v-card
                v-if="result.totalSize"
                flat
            >
                <v-card-text>
                    <div>Total Messages Size:</div>
                    <p class="display-1 text--primary text-center">
                        {{ result.totalSize | formatBytes(2) }}
                    </p>
                </v-card-text>
            </v-card>
            <v-flex md12>
                <material-card
                    :title="'Insights'"
                    color="primary"
                >
                    <v-data-table
                        :headers="headers"
                        :items="result.stats"
                        :loading-data="loading"
                        hide-actions
                    >
                        <template slot="no-data">
                            <div
                                class="text-xs-center"
                                style="width: 100%"
                            >
                                No display
                            </div>
                        </template>

                        <template
                            slot="headerCell"
                            slot-scope="{ header }"
                        >
                            <span
                                class="subheading font-weight-light text-primary text--darken-3"
                                v-text="header.text"
                            />
                        </template>
                        <template
                            slot="items"
                            slot-scope="{ item }"
                        >
                            <td>{{ item.date | formatDate('MMMM YYYY') }}</td>
                            <td>{{ item.count }} messages</td>
                            <td>{{ item.size | formatBytes(2) }}</td>
                        </template>
                    </v-data-table>
                </material-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import * as moment from 'moment';
import InsightRequests from '../utils/requests/insight';

export default {
    name: 'Insights',
    metaInfo() {
        return {
            title: 'Appmixer Backoffice - Insights'
        };
    },
    data() {
        return {
            dateFrom: moment().subtract(12, 'months').format('YYYY-MM'),
            dateTo: moment().format('YYYY-MM'),
            menuFrom: false,
            menuTo: false,
            headers: [
                {
                    sortable: false,
                    text: 'Month',
                    value: 'month'
                },
                {
                    sortable: false,
                    text: 'Number of Messages',
                    value: 'count'
                },
                {
                    sortable: false,
                    text: 'Messages Size',
                    value: 'size'
                }
            ],
            loading: false,
            result: {}
        };
    },
    created() {
        this.getInsights();
    },
    methods: {
        changeDates() {
            this.getInsights();
        },
        async getInsights() {
            this.loading = true;

            // const from = moment().subtract(12, 'months').format('YYYY-MM-DD');
            // const to = moment().format('YYYY-MM-DD');
            const from = moment(this.dateFrom).startOf('month').format('YYYY-MM-DD');
            const to = moment(this.dateTo).endOf('month').format('YYYY-MM-DD');
            try {
                this.result = await InsightRequests.getInsights(from, to);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.text-center {
    text-align: center;
}
.v-btn__content { color: black !important }
</style>
